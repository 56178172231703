import * as zod from 'zod';

export const FeatureFlagDataZod = zod.object({
  classifyStep: zod.boolean().default(false),
  locateStep: zod.boolean().default(false),
  runInBackgroundStep: zod.boolean().default(false),
  waypoints: zod.boolean().default(false),
  adHocFullSpeed: zod.boolean().default(false),
  dualModeCollision: zod.boolean().default(false),
  disableEstopHealthCheck: zod.boolean().default(false),
  jerkLimit: zod.boolean().default(false),
  enableScrewdriverPickup: zod.boolean().default(false),
  camera: zod.boolean().default(false),
  cameraRestartButton: zod.boolean().default(false),
  useHighResStream: zod.boolean().default(true),
  lowCameraFPS: zod.boolean().default(false),
  enableDepthStream: zod.boolean().default(false),
  enableDeveloperAPI: zod.boolean().default(false),
  guidedRoutines: zod.boolean().default(false),
  welcomeGuides: zod.boolean().default(false),
  enableJointLevelFirmwareUpdates: zod.boolean().default(false),
  darkMode: zod.boolean().default(false),
  adjustValuesOnInvalid: zod.boolean().default(false),
  alohaControl: zod.boolean().default(false),
  visualizePositionListsWhenNotEditing: zod.boolean().default(false),
  miniArm: zod.boolean().default(false),
  alohaRecorder: zod.boolean().default(false),
  enableFirmwareAutoUpdate: zod.boolean().default(false),
  rosMotions: zod.boolean().default(false),
  rosScaling: zod.boolean().default(false),
  rosMigration: zod.boolean().default(false),
  disableAverageTorqueLimit: zod.boolean().default(false),
  activityTimer: zod.boolean().default(false),
  activityTimerReducedTime: zod.boolean().default(false),
  enableFirmwareMismatch: zod.boolean().default(false),
  newCNCIntegration: zod.boolean().default(false),
  ewellixLiftTLT: zod.boolean().default(false),
  schunkEGxGripper: zod.boolean().default(false),
  legacyHaasSteps: zod.boolean().default(false),
  factoryReset: zod.boolean().default(false),
  weldMachineIntegration: zod.boolean().default(false),
  echoRecording: zod.boolean().default(false),
  allowHighAcceleration: zod.boolean().default(false),
  rebootAfterSoftwareUpdate: zod.boolean().default(true),
  enableCodeBlocks: zod.boolean().default(false),
  safetyOuts: zod.boolean().default(false),
  localAccuracyCalibration: zod.boolean().default(false),
  customDevices: zod.boolean().default(false),
  pythonExpressions: zod.boolean().default(false),
  backups: zod.boolean().default(false),
  offlineUpdate: zod.boolean().default(false),
  temperatureShutdown: zod.boolean().default(false),
});

export type FeatureFlagData = zod.infer<typeof FeatureFlagDataZod>;

export type FeatureFlagKey = keyof FeatureFlagData;

type Section =
  | 'vision'
  | 'movement'
  | 'equipment'
  | 'infra'
  | 'safety'
  | 'newUI'
  | 'other'
  | 'environmentAndSpace';

export type FeatureFlagDefinitionSchema = {
  title: string;
  allowSingleUser?: boolean; // allow to be used by one user - only works for browser based flags
  section?: Section;
  description?: string;
};

export const featureFlagDefinitions: Record<
  FeatureFlagKey,
  FeatureFlagDefinitionSchema
> = {
  camera: {
    title: 'Enable camera',
    section: 'vision',
    allowSingleUser: true,
  },
  cameraRestartButton: {
    title: 'Camera restart button',
    section: 'vision',
    description:
      'Show button to restart camera by running rtcwake command on host',
    allowSingleUser: true,
  },
  classifyStep: {
    title: 'Classify Step',
    section: 'vision',
    description: 'Classify/Categorize what the camera sees',
    allowSingleUser: true,
  },
  rosMotions: {
    title: 'Enable ROS2 motions',
    description: 'Use ROS2 motion for move page and routines',
    allowSingleUser: false,
    section: 'movement',
  },
  rosScaling: {
    title: 'Enable ROS2 scaling settings',
    description:
      'Enable ROS2 velocity and acceleration scaling in safety settings',
    allowSingleUser: false,
    section: 'movement',
  },
  rosMigration: {
    title: 'Migrate to ROS2',
    description: 'Migrates remaining functionality other than routines to ROS2',
    section: 'movement',
    allowSingleUser: false,
  },
  disableAverageTorqueLimit: {
    title: 'Disable average torque limit',
    description:
      'Disables the average torque limit validation when running motions',
    section: 'movement',
    allowSingleUser: false,
  },
  locateStep: {
    title: 'Locate Step',
    section: 'vision',
    description: 'Find objects in 3D space',
    allowSingleUser: true,
  },
  useHighResStream: {
    title: 'Use high resolution camera stream',
    section: 'vision',
    description: 'Use a higher resolution for the realsense camera stream',
    allowSingleUser: false,
  },
  lowCameraFPS: {
    title: 'Low camera FPS',
    section: 'vision',
    description: 'Lower the camera FPS to reduce hardware load',
    allowSingleUser: false,
  },
  enableDepthStream: {
    title: 'Enable Depth Stream',
    section: 'vision',
    description: 'Enables the depth stream on the Realsense wrist camera,',
    allowSingleUser: false,
  },
  runInBackgroundStep: {
    title: 'Run in background step',
    description:
      'Enable control step which allows running substeps in background',
    allowSingleUser: true,
  },
  waypoints: { title: 'Waypoints', section: 'movement', allowSingleUser: true },
  adHocFullSpeed: {
    title: 'Full Speed Adhoc Movements',
    section: 'movement',
    allowSingleUser: true,
  },
  dualModeCollision: {
    title: 'Collision acceleration thresholds',
    section: 'safety',
    allowSingleUser: true,
  },
  disableEstopHealthCheck: {
    title: 'Disable pre-unbrake Estop check',
    section: 'safety',
    allowSingleUser: true,
  },
  safetyOuts: {
    title: 'Safety outputs',
    section: 'safety',
    allowSingleUser: true,
    description: 'Enable feature to assign safeguard rules to outputs',
  },
  jerkLimit: {
    title: 'Jerk limit',
    section: 'movement',
    allowSingleUser: true,
  },
  enableScrewdriverPickup: {
    title: 'OnRobot Screwdriver pickup',
    section: 'equipment',
    allowSingleUser: true,
  },
  enableDeveloperAPI: { title: 'Developer API', allowSingleUser: true },
  guidedRoutines: { title: 'Guided routine setup', allowSingleUser: true },
  welcomeGuides: { title: 'Welcome guides', allowSingleUser: true },
  darkMode: { title: 'Dark mode', allowSingleUser: true },
  enableJointLevelFirmwareUpdates: {
    title: 'Firmware update UI',
    section: 'infra',
    allowSingleUser: true,
  },
  adjustValuesOnInvalid: {
    title: 'Adjust trajectory points',
    description: 'Adjust trajectory points instead of erroring out',
    section: 'infra',
    allowSingleUser: false,
  },
  factoryReset: {
    title: 'Reset user data',
    description: 'Show button to Reset user data',
    section: 'infra',
    allowSingleUser: true,
  },
  alohaControl: {
    title: 'Aloha control',
    description: 'Run Aloha backend and add move page widget for Aloha control',
  },
  miniArm: {
    title: 'Mini Arm',
    description: 'Add mini-arm control',
  },
  alohaRecorder: {
    title: 'Enable aloha recorder',
    description: 'Enables aloha recording UI and backend',
  },
  visualizePositionListsWhenNotEditing: {
    title: 'Visualize position lists when not editing',
    section: 'environmentAndSpace',
    allowSingleUser: true,
  },
  activityTimer: {
    title: 'Activity Timer',
    section: 'infra',
    allowSingleUser: true,
  },
  activityTimerReducedTime: {
    title: 'Activity Timer Reduced Time',
    section: 'infra',
    allowSingleUser: true,
  },
  enableFirmwareAutoUpdate: {
    title: 'Firmware auto update',
    section: 'infra',
    allowSingleUser: true,
  },
  enableFirmwareMismatch: {
    title: 'Firmware mismatch UI',
    section: 'infra',
    allowSingleUser: true,
  },
  newCNCIntegration: {
    title: 'New CNC integrations in equipment manager',
    section: 'equipment',
    allowSingleUser: true,
  },
  ewellixLiftTLT: {
    title: 'Ewellix Lift TLT',
    section: 'equipment',
    allowSingleUser: true,
  },
  schunkEGxGripper: {
    title: 'Schunk EGU/EGK grippers',
    section: 'equipment',
    allowSingleUser: true,
  },
  legacyHaasSteps: {
    title: 'Legacy Haas steps',
    description:
      'Include the legacy Haas steps “Monitor Haas machine” and “Run Haas program” in the step library',
    section: 'equipment',
    allowSingleUser: true,
  },
  weldMachineIntegration: {
    title: 'Weld machine integration',
    section: 'equipment',
    allowSingleUser: true,
  },
  echoRecording: {
    title: 'Echo recording',
    description: 'Enable echo recording panel',
    allowSingleUser: true,
  },
  allowHighAcceleration: {
    title: 'Allow high acceleration',
    description: 'Increase maximum acceleration for joints',
    allowSingleUser: true,
    section: 'safety',
  },
  rebootAfterSoftwareUpdate: {
    title: 'Reboot after software update',
    description: 'Reboots the control box after a software update',
    allowSingleUser: true,
    section: 'infra',
  },
  enableCodeBlocks: {
    title: 'Enable Code Blocks',
    description: 'Enable Code Blocks',
    section: 'infra',
  },
  localAccuracyCalibration: {
    title: 'Local Accuracy Calibration',
    section: 'environmentAndSpace',
    allowSingleUser: false,
    description:
      'Allows the user to calibrate the accuracy of the arm when moving to positions.',
  },
  customDevices: {
    title: 'Custom Devices',
    section: 'equipment',
    allowSingleUser: false,
    description: 'Enable custom devices',
  },
  pythonExpressions: {
    title: 'Python Expressions',
    section: 'infra',
    allowSingleUser: false,
    description: 'Enable python expressions',
  },
  backups: {
    title: 'Backups',
    section: 'infra',
    allowSingleUser: false,
    description: 'Show backups option in robot menu',
  },
  offlineUpdate: {
    title: 'Offline Software Update',
    section: 'infra',
    allowSingleUser: false,
    description: 'Enable software update from a USB',
  },
  temperatureShutdown: {
    title: 'Temperature Shutdown',
    section: 'safety',
    allowSingleUser: true,
    description: 'Shutdown control box when temperatures reach critical levels',
  },
};

type GroupedKeys = { section: Section; keys: FeatureFlagKey[] }[];

export const groupedFeatureFlags: GroupedKeys = (() => {
  const grouped: GroupedKeys = [];

  for (const keyStr of Object.keys(featureFlagDefinitions)) {
    const key = keyStr as FeatureFlagKey;
    let { section } = featureFlagDefinitions[key];

    if (!section) {
      section = 'other';
    }

    const group = grouped.find((g) => g.section === section);

    if (group) {
      group.keys.push(key);
    } else {
      grouped.push({ section, keys: [key] });
    }
  }

  // sort by section
  return grouped.sort((a, b) => {
    if (a.section === 'other') {
      return 1;
    }

    if (b.section === 'other') {
      return -1;
    }

    return a.section.localeCompare(b.section);
  });
})();
